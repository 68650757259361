<template>
  <router-view></router-view>
</template>

<script>
import {
  CLEAR_USER_ERRORS,
  CLEAR_USER_INFO
} from "@/core/services/store/core/user.module";
import { ADD_MENU_TYPE } from "@/core/services/store/system/menu.module";

export default {
  name: "Users",

  mounted() {
    this.clearStore();
    this.setMenuType();
  },

  methods: {
    clearStore() {
      this.$store.dispatch(CLEAR_USER_ERRORS);
      this.$store.dispatch(CLEAR_USER_INFO);
    },
    setMenuType() {
      this.$store.dispatch(ADD_MENU_TYPE, "root").then();
    }
  },

  watch: {
    $route: function() {
      this.clearStore();
    }
  }
};
</script>
